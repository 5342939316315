import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import GlobalStyle from './GlobalStyle';

import ServicesPage from './pages/ServicesPage';
import BrandStrategy from './pages/BrandStrategy';
import WebDevelopment from './pages/WebDevelopment';
import DigitalMarketing from './pages/DigitalMarketing';
import UXUIDesign from './pages/UXUIDesign';
import SEOOptimization from './pages/SEOOptimization';
import AboutPage from './pages/AboutPage';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import Careers from './pages/Careers';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import PricingPage from './pages/PricingPage';

import ScrollToTop from './components/ScrollToTop'; 
import Loader from './components/Loader';
import FloatingCTA from './components/FloatingCTA';

const Cursor = styled(motion.div)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--black);
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
`;

const HomePage = () => (
  <>
    <Hero />
    <Services />
    <Portfolio />
    <Testimonials />
    <About />
    <Contact />
  </>
);

function AppContent() {
  const [scrollY, setScrollY] = useState(0);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const location = useLocation(); 
  
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    const handleMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY });
    
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <ScrollToTop /> 
      <GlobalStyle />
      
      <AnimatePresence mode="wait">
        {loading ? (
          <Loader onLoadComplete={() => setLoading(false)} />
        ) : (
          <>
            <Cursor 
              animate={{ 
                x: mousePos.x - 10, 
                y: mousePos.y - 10,
                scale: 1
              }}
              transition={{
                type: "spring",
                damping: 25,
                stiffness: 300,
                mass: 0.5
              }}
            />
            <Header scrollY={scrollY} />
            
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<HomePage />} />
                
                <Route path="/services" element={<ServicesPage />} />
                
                <Route path="/services/brand-strategy" element={<BrandStrategy />} />
                <Route path="/services/web-development" element={<WebDevelopment />} />
                <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
                <Route path="/services/ux-ui-design" element={<UXUIDesign />} />
                <Route path="/services/seo-optimization" element={<SEOOptimization />} />
                
                <Route path="/about" element={<AboutPage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<ContactPage />} />
                
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
            </AnimatePresence>
            
            <Footer />
            <FloatingCTA />
          </>
        )}
      </AnimatePresence>
      
      <SpeedInsights />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;