import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TermsSection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(2.5rem, 6vw, 3.5rem);
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: -1px;
`;

const LastUpdated = styled.p`
  color: var(--gray);
  margin-bottom: 40px;
  font-size: 0.9rem;
`;

const Section = styled(motion.div)`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.8;
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
  
  li {
    margin-bottom: 10px;
    line-height: 1.8;
  }
`;

const TermsOfService = () => {
  return (
    <TermsSection>
      <Container>
        <PageTitle
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Terms of Service
        </PageTitle>
        
        <LastUpdated>Last Updated: April 8, 2025</LastUpdated>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          <SectionTitle>Agreement to Terms</SectionTitle>
          <Paragraph>
            These Terms of Service ("Terms") constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Auxena ("we," "us" or "our"), concerning your access to and use of the www.auxena.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Site").
          </Paragraph>
          <Paragraph>
            You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms. If you do not agree with all of these Terms, then you are expressly prohibited from using the Site and you must discontinue use immediately.
          </Paragraph>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <SectionTitle>Services</SectionTitle>
          <Paragraph>
            Auxena provides digital agency services including but not limited to brand strategy, web development, digital marketing, UX/UI design, and SEO optimization. All services are subject to separate service agreements that will be provided and must be agreed to before any work commences.
          </Paragraph>
          <Paragraph>
            We reserve the right to refuse service to anyone for any reason at any time. We may, without prior notice, change the services; stop providing the services or any features of the services; or create usage limits for the services.
          </Paragraph>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <SectionTitle>Intellectual Property Rights</SectionTitle>
          <Paragraph>
            Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights.
          </Paragraph>
          <Paragraph>
            Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content, and the Marks.
          </Paragraph>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <SectionTitle>User Representations</SectionTitle>
          <Paragraph>
            By using the Site, you represent and warrant that:
          </Paragraph>
          <List>
            <li>All registration information you submit will be true, accurate, current, and complete.</li>
            <li>You will maintain the accuracy of such information and promptly update such registration information as necessary.</li>
            <li>You have the legal capacity and you agree to comply with these Terms.</li>
            <li>You are not a minor in the jurisdiction in which you reside.</li>
            <li>You will not access the Site through automated or non-human means.</li>
            <li>You will not use the Site for any illegal or unauthorized purpose.</li>
            <li>Your use of the Site will not violate any applicable law or regulation.</li>
          </List>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>
            If you have any questions about these Terms, please contact us at:
          </Paragraph>
          <Paragraph>
            <strong>Auxena</strong><br />
            Email: legal@auxena.com<br />
            Address: New York, New York City, 11365<br />
            Phone: +1 (347) 229-5937
          </Paragraph>
        </Section>
      </Container>
    </TermsSection>
  );
};

export default TermsOfService;