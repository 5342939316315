import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CareersSection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(3rem, 8vw, 4.5rem);
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const PageSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto;
`;

const CultureSection = styled.div`
  margin-bottom: 100px;
`;

const CultureTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
`;

const CultureText = styled(motion.p)`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 40px;
  color: var(--gray);
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(motion.div)`
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ValueIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const ValueTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ValueText = styled.p`
  color: var(--gray);
  line-height: 1.6;
`;

const JobsSection = styled.div`
  margin-bottom: 80px;
`;

const JobsTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: -0.5px;
`;

const JobsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const JobCard = styled(motion.div)`
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const JobInfo = styled.div``;

const JobTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const JobMeta = styled.div`
  display: flex;
  gap: 20px;
  color: var(--gray);
  
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const JobLocation = styled.span``;

const JobType = styled.span``;

const ApplyButton = styled.button`
  padding: 12px 24px;
  background: var(--black);
  color: var(--white);
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    transform: translateY(-3px);
  }
`;

const NoJobs = styled.div`
  padding: 60px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--gray);
`;

const Careers = () => {
  const values = [
    {
      icon: "✦",
      title: "Creativity",
      text: "We approach every challenge with fresh thinking and imagination, pushing beyond conventional solutions."
    },
    {
      icon: "◆",
      title: "Excellence",
      text: "We hold ourselves to the highest standards in every aspect of our work, constantly striving to exceed expectations."
    },
    {
      icon: "✧",
      title: "Collaboration",
      text: "We believe the best results come from combining diverse perspectives and working together toward shared goals."
    },
    {
      icon: "◈",
      title: "Growth",
      text: "We're committed to continuous learning and development, both as individuals and as a company."
    },
    {
      icon: "◇",
      title: "Impact",
      text: "We measure our success by the meaningful difference our work makes for our clients and their customers."
    },
    {
      icon: "✺",
      title: "Integrity",
      text: "We act with honesty, transparency, and ethical purpose in all our business relationships."
    }
  ];

  const jobs = [
    {
      title: "UI/UX Designer",
      location: "Remote",
      type: "Part-time"
    },
    {
      title: "Backend Developer",
      location: "Remote",
      type: "Part-time"
    },
    {
      title: "Frontend Developer",
      location: "Remote",
      type: "Part-time"
    }
  ];

  return (
    <CareersSection>
      <Container>
        <PageHeader>
          <PageTitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Join Our Team
          </PageTitle>
          <PageSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            We're building a team of talented individuals who are passionate about creating exceptional digital experiences.
          </PageSubtitle>
        </PageHeader>
        
        <CultureSection>
          <CultureTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Our Culture
          </CultureTitle>
          <CultureText
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            At Auxena, we've built a culture that celebrates creativity, embraces innovation, and values diverse perspectives. 
            We believe in giving our team members the autonomy to do their best work, supported by mentorship and growth opportunities.
            We foster an environment where everyone can thrive professionally while maintaining a healthy work-life balance.
          </CultureText>
          
          <ValuesGrid>
            {values.map((value, index) => (
              <ValueCard
                key={index}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
              >
                <ValueIcon>{value.icon}</ValueIcon>
                <ValueTitle>{value.title}</ValueTitle>
                <ValueText>{value.text}</ValueText>
              </ValueCard>
            ))}
          </ValuesGrid>
        </CultureSection>
        
        <JobsSection>
          <JobsTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Open Positions
          </JobsTitle>
          
          <JobsList>
            {jobs.length > 0 ? (
              jobs.map((job, index) => (
                <JobCard
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <JobInfo>
                    <JobTitle>{job.title}</JobTitle>
                    <JobMeta>
                      <JobLocation>{job.location}</JobLocation>
                      <JobType>{job.type}</JobType>
                    </JobMeta>
                  </JobInfo>
                  <ApplyButton>Apply Now</ApplyButton>
                </JobCard>
              ))
            ) : (
              <NoJobs>
                <h3>No open positions at the moment</h3>
                <p>Please check back later or send your resume to careers@auxena.com</p>
              </NoJobs>
            )}
          </JobsList>
        </JobsSection>
      </Container>
    </CareersSection>
  );
};

export default Careers;