import React from 'react';
import PageTemplate from '../components/PageTemplate';

const UXUIDesign = () => {
  const pageData = {
    title: "UX/UI Design",
    subtitle: "User-centered design that creates intuitive, engaging digital experiences that delight your customers and achieve your business goals.",
    sections: [
      {
        title: "User Experience Research",
        paragraphs: [
          "Great design starts with understanding your users. We conduct in-depth research—including interviews, usability testing, and analytics analysis—to uncover insights about user behaviors, needs, and pain points.",
          "Our research informs every design decision, ensuring we create experiences that truly resonate with your audience. By understanding the why behind user actions, we design solutions that solve real problems."
        ],
        image: "https://images.unsplash.com/photo-1525130413817-d45c1d127c42?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Interface Design",
        paragraphs: [
          "We create beautiful, functional interfaces that balance aesthetic appeal with usability. Our designs follow established patterns when appropriate while introducing unique elements that reflect your brand identity.",
          "Every button, form, and interaction is crafted with intention, creating a cohesive system that feels intuitive to users while driving them toward conversion goals."
        ],
        image: "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Prototyping & User Testing",
        paragraphs: [
          "We believe in validating designs early and often. Through interactive prototypes, we bring concepts to life and test them with real users before committing to development.",
          "This iterative approach reduces risk and ensures the final product truly meets user needs. By observing how people interact with prototypes, we identify opportunities for improvement and refinement."
        ],
        image: "https://images.unsplash.com/photo-1542744094-3a31f272c490?q=80&w=800&auto=format&fit=crop"
      }
    ]
  };

  return <PageTemplate {...pageData} />;
};

export default UXUIDesign;