import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation, useMotionValue, useSpring, useTransform } from 'framer-motion';

const ContactSection = styled.section`
  padding: 120px 0;
  background-color: #f9f9f9;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const ContactContent = styled.div``;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const ContactText = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--gray);
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ContactInfo = styled.div`
  margin-bottom: 40px;
`;

const InfoItem = styled(motion.div)`
  margin-bottom: 20px;
`;

const InfoLabel = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

const InfoValue = styled.p`
  color: var(--gray);
`;

const ContactForm = styled(motion.form)`
  background: var(--white);
  padding: 40px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-family: inherit;
  background: transparent;
  transition: var(--transition);
  
  &:focus {
    outline: none;
    border-color: var(--black);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-family: inherit;
  background: transparent;
  min-height: 150px;
  resize: vertical;
  transition: var(--transition);
  
  &:focus {
    outline: none;
    border-color: var(--black);
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  width: fit-content;
  overflow: visible;
  margin-top: 10px;
`;

const SubmitButtonWrapper = styled(motion.div)`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 800px;
`;

const SubmitButton = styled(motion.button)`
  position: relative;
  padding: 18px 36px;
  background: var(--black);
  color: var(--white);
  font-size: 1rem;
  letter-spacing: 1.5px;
  border: none;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
  transform-style: preserve-3d;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent 25%, rgba(255,255,255,0.1) 50%, transparent 75%);
    background-size: 200% 200%;
    z-index: -1;
  }
`;

const ButtonText = styled(motion.span)`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ButtonBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  z-index: 0;
  border-radius: 4px;
  transition: opacity 0.3s ease;
`;

const Circle = styled(motion.div)`
  position: absolute;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  z-index: 0;
`;

const SuccessIcon = styled(motion.svg)`
  width: 20px;
  height: 20px;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [buttonState, setButtonState] = useState('idle'); 
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef(null);
  const controls = useAnimation();
  
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  
  const springConfig = { damping: 15, stiffness: 150 };
  const springX = useSpring(x, springConfig);
  const springY = useSpring(y, springConfig);

  const moveX = useTransform(springX, [-20, 20], [-5, 5]);
  const moveY = useTransform(springY, [-20, 20], [-5, 5]);
  
  const handleMouseMove = (e) => {
    if (!buttonRef.current) return;
    
    const rect = buttonRef.current.getBoundingClientRect();
    const buttonCenterX = rect.left + rect.width / 2;
    const buttonCenterY = rect.top + rect.height / 2;
    
    const mouseX = e.clientX - buttonCenterX;
    const mouseY = e.clientY - buttonCenterY;
    
    const maxDistance = 100;
    const distance = Math.sqrt(mouseX * mouseX + mouseY * mouseY);
    
    if (distance < maxDistance) {
      const strength = 20;
      x.set((mouseX / maxDistance) * strength);
      y.set((mouseY / maxDistance) * strength);
      setMousePosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    } else {
      x.set(0);
      y.set(0);
    }
  };
  
  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };
  
  useEffect(() => {
    if (buttonState === 'idle') {
      controls.start({
        background: 'linear-gradient(45deg, #000000, #333333)',
        transition: { duration: 0.3 }
      });
    } else if (buttonState === 'loading') {
      controls.start({
        background: 'linear-gradient(45deg, #000000, #333333)',
        transition: { duration: 0.3 }
      });
    } else if (buttonState === 'success') {
      controls.start({
        background: 'linear-gradient(45deg, #000000, #333333)',
        transition: { duration: 0.3 }
      });
    }
  }, [buttonState, controls]);
  
  const handleButtonClick = (e) => {
    if (!buttonRef.current || buttonState !== 'idle') return;
    
    const rect = buttonRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const rippleSize = Math.max(rect.width, rect.height) * 2;
    
    const circle = document.createElement('div');
    circle.style.position = 'absolute';
    circle.style.width = `${rippleSize}px`;
    circle.style.height = `${rippleSize}px`;
    circle.style.top = `${y - rippleSize / 2}px`;
    circle.style.left = `${x - rippleSize / 2}px`;
    circle.style.background = 'rgba(255, 255, 255, 0.15)';
    circle.style.borderRadius = '50%';
    circle.style.transform = 'scale(0)';
    circle.style.transition = 'transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s cubic-bezier(0.23, 1, 0.32, 1)';
    circle.style.zIndex = '0';
    circle.style.pointerEvents = 'none';
    
    buttonRef.current.appendChild(circle);
    
    setTimeout(() => {
      circle.style.transform = 'scale(1)';
      circle.style.opacity = '0';
    }, 10);
    
    setTimeout(() => {
      if (buttonRef.current && buttonRef.current.contains(circle)) {
        buttonRef.current.removeChild(circle);
      }
    }, 600);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!formData.name || !formData.email || !formData.message) {
      // You could add a state for error messages here
      console.error('All fields are required');
      return;
    }
    
    setButtonState('loading');
    
    try {
      const response = await fetch('/api/submit-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }
      
      // Success
      setButtonState('success');
      
      // Reset form after a delay
      setTimeout(() => {
        setFormData({ name: '', email: '', message: '' });
        setButtonState('idle');
      }, 2000);
    } catch (error) {
      console.error('Form submission error:', error);
      // You could handle error states better here
      setButtonState('idle');
      // Optionally set an error state to display to the user
    }
  };
  
  return (
    <ContactSection id="contact">
      <Container>
        <ContactContent>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Get In Touch
          </SectionTitle>
          <ContactText
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            Have a project in mind or want to know more about our services? 
            Reach out to us and let's create something amazing together.
          </ContactText>
          
          <ContactInfo>
            <InfoItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <InfoLabel>Email</InfoLabel>
              <InfoValue>auxena@gmail.com</InfoValue>
            </InfoItem>
            <InfoItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <InfoLabel>Phone</InfoLabel>
              <InfoValue>+1 (347) 229-5937</InfoValue>
            </InfoItem>
            <InfoItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <InfoLabel>Address</InfoLabel>
              <InfoValue>New York, New York City, 11365</InfoValue>
            </InfoItem>
          </ContactInfo>
        </ContactContent>
        
        <ContactForm
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input 
              type="text" 
              id="name" 
              name="name" 
              required 
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input 
              type="email" 
              id="email" 
              name="email" 
              required 
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message">Message</Label>
            <TextArea 
              id="message" 
              name="message" 
              required 
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
            />
          </FormGroup>
          
          <ButtonContainer 
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <SubmitButtonWrapper
              ref={buttonRef}
              onClick={handleButtonClick}
              style={{
                x: moveX,
                y: moveY
              }}
              animate={{
                boxShadow: buttonState === 'idle' 
                  ? '0 10px 30px rgba(0, 0, 0, 0.1)' 
                  : buttonState === 'success'
                  ? '0 10px 30px rgba(0, 0, 0, 0.2)'
                  : '0 5px 15px rgba(0, 0, 0, 0.1)'
              }}
              whileHover={{
                scale: 1.02,
                boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)'
              }}
              whileTap={{ scale: 0.98 }}
            >
              <SubmitButton 
                type="submit"
                disabled={buttonState !== 'idle'}
                animate={controls}
              >
                <ButtonText
                  initial={false}
                  animate={buttonState === 'loading' 
                    ? { opacity: 1, y: 0 }
                    : buttonState === 'success'
                    ? { opacity: 1, y: 0 }
                    : { opacity: 1, y: 0 }
                  }
                >
                  {buttonState === 'idle' && 'Send Message'}
                  
                  {buttonState === 'loading' && (
                    <>
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ repeat: Infinity, duration: 1, ease: "linear" }}
                      >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="white" strokeWidth="2" strokeDasharray="60" strokeDashoffset="60" strokeLinecap="round" strokeLinejoin="round">
                            <animate attributeName="stroke-dashoffset" values="60;0" dur="1s" repeatCount="indefinite" />
                          </path>
                        </svg>
                      </motion.div>
                      Processing...
                    </>
                  )}
                  
                  {buttonState === 'success' && (
                    <>
                      <SuccessIcon viewBox="0 0 24 24" fill="none">
                        <motion.path
                          d="M6 12L10 16L18 8"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          initial={{ pathLength: 0 }}
                          animate={{ pathLength: 1 }}
                          transition={{ duration: 0.3, ease: "easeOut" }}
                        />
                      </SuccessIcon>
                      Sent!
                    </>
                  )}
                </ButtonText>
                
                <ButtonBackground 
                  whileHover={{ 
                    backgroundPosition: ['0% 0%', '100% 100%']
                  }}
                  transition={{ 
                    duration: 1.5, 
                    repeat: Infinity, 
                    repeatType: "reverse" 
                  }}
                />
              </SubmitButton>
            </SubmitButtonWrapper>
          </ButtonContainer>
        </ContactForm>
      </Container>
    </ContactSection>
  );
};

export default Contact;