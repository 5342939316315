import React from 'react';
import PageTemplate from '../components/PageTemplate';

const WebDevelopment = () => {
  const pageData = {
    title: "Web Development",
    subtitle: "Custom websites with clean code and stunning designs that perform across all devices and drive business results.",
    sections: [
      {
        title: "Custom Website Development",
        paragraphs: [
          "We build websites from the ground up, tailored specifically to your business needs and goals. No templates, no compromises—just clean, efficient code that performs beautifully.",
          "Our development process follows industry best practices to ensure your site is fast, secure, and easy to maintain. We prioritize performance optimization, responsive design, and accessibility to create websites that work for everyone."
        ],
        image: "https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "E-Commerce Solutions",
        paragraphs: [
          "From small boutiques to large online stores, we create e-commerce experiences that drive sales. Our solutions combine attractive product displays with seamless checkout processes to maximize conversions.",
          "We integrate payment gateways, inventory management, shipping solutions, and customer accounts into a cohesive system that's easy to manage. With deep analytics integration, you'll gain valuable insights into customer behavior and sales patterns."
        ],
        image: "https://images.unsplash.com/photo-1563013544-824ae1b704d3?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Web Application Development",
        paragraphs: [
          "When your business needs go beyond a standard website, we create custom web applications that automate processes, manage data, and create unique user experiences that set you apart.",
          "Using modern frameworks and architectures, we build scalable applications that can grow with your business. Our focus on user experience ensures that even complex functionality remains intuitive and accessible to your users."
        ],
        image: "https://images.unsplash.com/photo-1573495612937-f01934eeaaa7?q=80&w=800&auto=format&fit=crop"
      }
    ]
  };

  return <PageTemplate {...pageData} />;
};

export default WebDevelopment;