import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Portfolio from '../components/Portfolio';

const PortfolioPageSection = styled.div`
  padding-top: 80px;
`;

const PortfolioPage = () => {
  return (
    <PortfolioPageSection>
      <Portfolio />
    </PortfolioPageSection>
  );
};

export default PortfolioPage;