import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Button = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: var(--black);
  color: white;
  padding: 16px 28px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    z-index: -1;
  }
`;

const ButtonText = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const buttonVariants = {
  hidden: { 
    opacity: 0, 
    y: 20,
    scale: 0.9
  },
  visible: { 
    opacity: 1, 
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25,
      duration: 0.6
    }
  },
  exit: { 
    opacity: 0, 
    y: 20, 
    scale: 0.9,
    transition: {
      duration: 0.5, 
      ease: "easeOut"
    }
  }
};

const pathVariants = {
  hidden: { 
    pathLength: 0,
    opacity: 0
  },
  visible: { 
    pathLength: 1,
    opacity: 1,
    transition: { 
      duration: 0.4,
      ease: "easeOut"
    }
  }
};

const FloatingCTA = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  
  useEffect(() => {
    if (window.scrollY > 300) {
      setIsVisible(true);
      setShouldRender(true);
    }
    const handleScroll = debounce(() => {
      const scrollThreshold = 300;
      
      if (window.scrollY > scrollThreshold) {
        if (!shouldRender) setShouldRender(true);
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 50);
    
    window.addEventListener('scroll', handleScroll);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [shouldRender]);
  useEffect(() => {
    if (!isVisible && shouldRender) {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 600); 
      
      return () => clearTimeout(timer);
    }
  }, [isVisible, shouldRender]);
  
  return (
    <AnimatePresence mode="wait">
      {(shouldRender) && (
        <Button
          as={Link}
          to="/contact"
          variants={buttonVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "exit"}
          exit="exit"
          key="floating-cta"
          whileHover={{ 
            scale: 1.05, 
            boxShadow: "0 15px 30px rgba(0, 0, 0, 0.3)",
            y: -5
          }}
          whileTap={{ 
            scale: 0.95, 
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" 
          }}
        >
          <IconWrapper>
            <motion.svg 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              initial="hidden"
              animate={isVisible ? "visible" : "hidden"}
            >
              <motion.path 
                d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4Z" 
                stroke="white" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                variants={pathVariants}
              />
              <motion.path 
                d="M22 6L12 13L2 6" 
                stroke="white" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                variants={pathVariants}
              />
            </motion.svg>
          </IconWrapper>
          <ButtonText>Get in Touch</ButtonText>
        </Button>
      )}
    </AnimatePresence>
  );
};

export default FloatingCTA;