import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PortfolioSection = styled.section`
  padding: 120px 0;
  background-color: #f9f9f9;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionHeader = styled.div`
  margin-bottom: 80px;
  max-width: 700px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PortfolioItem = styled(motion.a)`
  position: relative;
  height: 450px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: inherit;
`;

const ProjectImage = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: var(--gray);
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
  
  ${PortfolioItem}:hover & {
    transform: scale(1.05);
  }
`;

const ProjectOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  
  ${PortfolioItem}:hover & {
    opacity: 1;
  }
`;

const ProjectTitle = styled.h3`
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

const ProjectCategory = styled.span`
  color: var(--white);
  font-size: 1rem;
  opacity: 0.8;
`;

const ViewProjectButton = styled.span`
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 15px;
  display: inline-block;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  
  ${PortfolioItem}:hover & {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Portfolio = () => {
  const projects = [
    {
      title: "Vix Coffee",
      category: "Brand Strategy & Website",
      image: "https://images.unsplash.com/photo-1501339847302-ac426a4a7cbb?q=80&w=1200&auto=format&fit=crop",
      url: "https://vixcoffee.vercel.app/"
    },
    {
      title: "Nexus Technologies",
      category: "Web Development",
      image: "https://e5qjgyxhp85.exactdn.com/wp-content/uploads/2024/06/Product-Image-Gen-AI-powered-Solutions-jpg.jpg?strip=all&lossy=1&ssl=1",
      url: "https://nexustechnologies.vercel.app/"
    },
  ];

  return (
    <PortfolioSection id="portfolio">
      <Container>
        <SectionHeader>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Our Work
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            Explore our most recent projects where we've helped brands elevate their digital presence.
          </SectionSubtitle>
        </SectionHeader>

        <PortfolioGrid>
          {projects.map((project, index) => (
            <PortfolioItem
              key={index}
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <ProjectImage image={project.image} />
              <ProjectOverlay>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectCategory>{project.category}</ProjectCategory>
                <ViewProjectButton>View Project</ViewProjectButton>
              </ProjectOverlay>
            </PortfolioItem>
          ))}
        </PortfolioGrid>
      </Container>
    </PortfolioSection>
  );
};

export default Portfolio;