import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import About from '../components/About';

const AboutPageSection = styled.div`
  padding-top: 80px;
`;

const AboutPage = () => {
  return (
    <AboutPageSection>
      <About />
    </AboutPageSection>
  );
};

export default AboutPage;