import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PageSection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(3rem, 8vw, 4.5rem);
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const PageSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto;
`;

const PageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: start;
  margin-bottom: 80px;
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
  
  &:nth-child(even) {
    direction: rtl;
    
    > * {
      direction: ltr;
    }
    
    @media (max-width: 900px) {
      direction: ltr;
    }
  }
`;

const ContentText = styled.div``;

const ContentTitle = styled(motion.h2)`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
`;

const ContentDescription = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--gray);
  line-height: 1.8;
  margin-bottom: 20px;
`;

const ContentImage = styled(motion.div)`
  height: 400px;
  background-color: #f0f0f0;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  
  @media (max-width: 900px) {
    height: 300px;
  }
`;

const PageTemplate = ({ title, subtitle, sections }) => {
  return (
    <PageSection>
      <Container>
        <PageHeader>
          <PageTitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {title}
          </PageTitle>
          <PageSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            {subtitle}
          </PageSubtitle>
        </PageHeader>
        
        {sections.map((section, index) => (
          <PageContent key={index}>
            <ContentText>
              <ContentTitle
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                {section.title}
              </ContentTitle>
              {section.paragraphs.map((paragraph, i) => (
                <ContentDescription
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: 0.1 * i }}
                >
                  {paragraph}
                </ContentDescription>
              ))}
            </ContentText>
            <ContentImage 
              image={section.image}
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            />
          </PageContent>
        ))}
      </Container>
    </PageSection>
  );
};

export default PageTemplate;