import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PolicySection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(2.5rem, 6vw, 3.5rem);
  font-weight: 600;
  margin-bottom: 40px;
  letter-spacing: -1px;
`;

const LastUpdated = styled.p`
  color: var(--gray);
  margin-bottom: 40px;
  font-size: 0.9rem;
`;

const Section = styled(motion.div)`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.8;
`;

const List = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
  
  li {
    margin-bottom: 10px;
    line-height: 1.8;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PolicySection>
      <Container>
        <PageTitle
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Privacy Policy
        </PageTitle>
        
        <LastUpdated>Last Updated: April 8, 2025</LastUpdated>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          <SectionTitle>Introduction</SectionTitle>
          <Paragraph>
            Auxena ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this privacy policy carefully. If you disagree with its terms, please discontinue use of our website immediately.
          </Paragraph>
          <Paragraph>
            We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the "Last Updated" date of this policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the website. You are encouraged to periodically review this Privacy Policy to stay informed of updates.
          </Paragraph>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <SectionTitle>Collection of Your Information</SectionTitle>
          <Paragraph>
            We may collect information about you in a variety of ways. The information we may collect via the website includes:
          </Paragraph>
          <List>
            <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, email address, and telephone number, that you voluntarily give to us when you fill out forms or contact us through our website.</li>
            <li><strong>Financial Data:</strong> Financial information, such as payment method and account details, that you provide when you purchase our services. Financial information is stored by our payment processor, and this Privacy Policy does not apply to their information collection practices.</li>
            <li><strong>Derived Data:</strong> Information our servers automatically collect when you access the website, such as your IP address, browser type, operating system, access times, and the pages you have viewed on our website.</li>
            <li><strong>Data from Third Parties:</strong> Information from third parties, such as social media platforms, if you connect to them through our website or share our content on them.</li>
          </List>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          <SectionTitle>Use of Your Information</SectionTitle>
          <Paragraph>
            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. We may use information collected about you via the website to:
          </Paragraph>
          <List>
            <li>Create and manage your account.</li>
            <li>Fulfill and manage orders, payments, and other transactions related to the website.</li>
            <li>Increase the efficiency and operation of the website.</li>
            <li>Monitor and analyze usage and trends to improve your experience with the website.</li>
            <li>Notify you of updates to the website.</li>
            <li>Offer new products, services, and/or recommendations to you.</li>
            <li>Resolve disputes and troubleshoot problems.</li>
            <li>Prevent potentially prohibited or illegal activities.</li>
            <li>Request feedback and contact you about your use of the website.</li>
          </List>
        </Section>
        
        <Section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>
            If you have questions or comments about this Privacy Policy, please contact us at:
          </Paragraph>
          <Paragraph>
            <strong>Auxena</strong><br />
            Email: privacy@auxena.com<br />
            Address: New York, New York City, 11365<br />
            Phone: +1 (347) 229-5937
          </Paragraph>
        </Section>
      </Container>
    </PolicySection>
  );
};

export default PrivacyPolicy;