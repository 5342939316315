import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const FooterSection = styled.footer`
  padding: 80px 0 40px;
  background-color: var(--black);
  color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const Column = styled.div``;

const LogoWrapper = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 400px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
`;

const SocialLink = styled(motion.a)`
  color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  
  &:hover {
    background: var(--white);
    color: var(--black);
  }
`;

const ColumnTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 20px;
`;

const NavLinks = styled.ul`
  list-style: none;
`;

const NavItem = styled.li`
  margin-bottom: 10px;
`;

const NavLink = styled(motion(Link))`
  color: rgba(255, 255, 255, 0.7);
  transition: var(--transition);
  
  &:hover {
    color: var(--white);
  }
`;

const Copyright = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
`;

const LegalLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const LegalLink = styled(Link)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  transition: var(--transition);
  
  &:hover {
    color: var(--white);
  }
`;

const Footer = () => {
  return (
    <FooterSection>
      <Container>
        <FooterTop>
          <Column>
            <LogoWrapper>
              <Logo color="var(--white)" textColor="var(--white)" />
            </LogoWrapper>
            <Description>
              We create digital experiences that elevate brands and drive growth.
            </Description>
            <SocialLinks>
              <SocialLink 
                href="#" 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                ✦
              </SocialLink>
              <SocialLink 
                href="#" 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                ✧
              </SocialLink>
              <SocialLink 
                href="#" 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                ◆
              </SocialLink>
              <SocialLink 
                href="#" 
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                ◇
              </SocialLink>
            </SocialLinks>
          </Column>
          
          <Column>
            <ColumnTitle>Services</ColumnTitle>
            <NavLinks>
              <NavItem>
                <NavLink to="/services/brand-strategy" whileHover={{ x: 5 }}>Brand Strategy</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/services/web-development" whileHover={{ x: 5 }}>Web Development</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/services/digital-marketing" whileHover={{ x: 5 }}>Digital Marketing</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/services/ux-ui-design" whileHover={{ x: 5 }}>UX/UI Design</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/services/seo-optimization" whileHover={{ x: 5 }}>SEO Optimization</NavLink>
              </NavItem>
            </NavLinks>
          </Column>
          
          <Column>
            <ColumnTitle>Company</ColumnTitle>
            <NavLinks>
              <NavItem>
                <NavLink to="/about" whileHover={{ x: 5 }}>About Us</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/portfolio" whileHover={{ x: 5 }}>Portfolio</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/careers" whileHover={{ x: 5 }}>Careers</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact" whileHover={{ x: 5 }}>Contact</NavLink>
              </NavItem>
            </NavLinks>
          </Column>
          
          <Column>
            <ColumnTitle>Contact</ColumnTitle>
            <NavLinks>
              <NavItem>
                <NavLink href="mailto:auxenastudios@gmail.com">auxenastudios@gmail.com</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="tel:+13472295937">+1 (347) 229-5937</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">New York, New York City, 11365</NavLink>
              </NavItem>
            </NavLinks>
          </Column>
        </FooterTop>
        
        <FooterBottom>
          <Copyright>© {new Date().getFullYear()} Auxena. All rights reserved.</Copyright>
          <LegalLinks>
            <LegalLink to="/privacy-policy">Privacy Policy</LegalLink>
            <LegalLink to="/terms-of-service">Terms of Service</LegalLink>
          </LegalLinks>
        </FooterBottom>
      </Container>
    </FooterSection>
  );
};

export default Footer;