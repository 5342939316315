import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ServicesPageSection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(3rem, 8vw, 4.5rem);
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const PageSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto 60px;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled(motion.div)`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 4px;
  transition: var(--transition);
  
  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`;

const ServiceCardLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
`;

const ServiceImage = styled.div`
  width: 100%;
  height: 240px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
`;

const ServiceContent = styled.div`
  padding: 30px;
`;

const ServiceIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

const ServiceDescription = styled.p`
  color: var(--gray);
  margin-bottom: 20px;
`;

const ReadMoreLink = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: var(--black);
  
  &::after {
    content: "→";
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  ${ServiceCardLink}:hover & {
    &::after {
      transform: translateX(5px);
    }
  }
`;

const ServicesPage = () => {
  const services = [
    {
      icon: "✦",
      title: "Brand Strategy",
      description: "We build brands that stand out with strategic positioning and compelling storytelling that resonates with your audience.",
      link: "/services/brand-strategy",
      image: "https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=800&auto=format&fit=crop"
    },
    {
      icon: "✧",
      title: "Web Development",
      description: "Custom websites with clean code and stunning designs that perform across all devices and drive business results.",
      link: "/services/web-development",
      image: "https://images.unsplash.com/photo-1593720213428-28a5b9e94613?q=80&w=800&auto=format&fit=crop"
    },
    {
      icon: "◆",
      title: "Digital Marketing",
      description: "Data-driven marketing strategies that connect with your audience and drive measurable growth for your business.",
      link: "/services/digital-marketing",
      image: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=800&auto=format&fit=crop"
    },
    {
      icon: "◇",
      title: "UX/UI Design",
      description: "User-centered design that creates intuitive, engaging digital experiences that delight your customers.",
      link: "/services/ux-ui-design",
      image: "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?q=80&w=800&auto=format&fit=crop"
    },
    {
      icon: "◈",
      title: "SEO Optimization",
      description: "Boost your visibility with search strategies that drive organic traffic, improve rankings, and deliver results.",
      link: "/services/seo-optimization",
      image: "https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?q=80&w=800&auto=format&fit=crop"
    },
    {
      icon: "✺",
      title: "Content Creation",
      description: "Engaging copy, visuals, and media that bring your brand's voice to life and keep audiences hooked.",
      link: "#", // You can create this page as well if needed
      image: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?q=80&w=800&auto=format&fit=crop"
    }
  ];

  return (
    <ServicesPageSection>
      <Container>
        <PageHeader>
          <PageTitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Our Services
          </PageTitle>
          <PageSubtitle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            We deliver end-to-end digital solutions that help businesses transform and thrive in an increasingly digital world. Explore our services below.
          </PageSubtitle>
        </PageHeader>
        
        <ServicesGrid>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <ServiceCardLink to={service.link}>
                <ServiceImage image={service.image} />
                <ServiceContent>
                  <ServiceIcon>{service.icon}</ServiceIcon>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDescription>{service.description}</ServiceDescription>
                  <ReadMoreLink>Learn more</ReadMoreLink>
                </ServiceContent>
              </ServiceCardLink>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>
    </ServicesPageSection>
  );
};

export default ServicesPage;