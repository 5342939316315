import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LoaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const LoaderInner = styled(motion.div)`
  text-align: center;
`;

const LogoContainer = styled(motion.div)`
  margin-bottom: 20px;
`;

const ProgressBar = styled(motion.div)`
  width: 120px;
  height: 3px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
`;

const ProgressFill = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--black);
`;

const LoaderText = styled(motion.p)`
  margin-top: 15px;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--gray);
`;

const Loader = ({ onLoadComplete }) => {
  return (
    <LoaderContainer
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.8, ease: [0.22, 1, 0.36, 1] } }}
    >
      <LoaderInner>
        <LogoContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <svg width="50" height="50" viewBox="0 0 100 100" fill="black">
            <motion.path 
              d="M25,20 C35,10 65,15 75,25 C85,35 90,65 80,75 C70,85 40,85 25,75 C15,65 15,30 25,20 Z"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 2, ease: "easeInOut" }}
            />
          </svg>
        </LogoContainer>
        
        <ProgressBar>
          <ProgressFill
            initial={{ x: '-100%' }}
            animate={{ x: '0%' }}
            transition={{ 
              duration: 2.5, 
              ease: [0.22, 1, 0.36, 1],
              onComplete: onLoadComplete 
            }}
          />
        </ProgressBar>
        
        <LoaderText
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Auxena
        </LoaderText>
      </LoaderInner>
    </LoaderContainer>
  );
};

export default Loader;