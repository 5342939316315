import React from 'react';
import PageTemplate from '../components/PageTemplate';

const BrandStrategy = () => {
  const pageData = {
    title: "Brand Strategy",
    subtitle: "We build brands that stand out with strategic positioning and compelling storytelling, creating memorable experiences that resonate with your audience.",
    sections: [
      {
        title: "Brand Identity Development",
        paragraphs: [
          "Your brand identity is more than just a logo—it's the entire visual and emotional experience people have when interacting with your business. We develop comprehensive brand identities that capture your essence and communicate your values.",
          "Our strategic approach begins with understanding your business goals, target audience, and competitive landscape. From there, we craft a unique visual language—including logo, color palette, typography, and imagery—that sets you apart in the marketplace."
        ],
        image: "https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Brand Positioning & Messaging",
        paragraphs: [
          "In today's crowded marketplace, having a clear position is essential. We help define what makes your brand unique and how to articulate that difference in a way that resonates with your ideal customers.",
          "Our messaging frameworks provide the foundation for all your communications, ensuring consistency across channels while allowing flexibility for different audiences and contexts. We'll help you find your brand voice and use it effectively."
        ],
        image: "https://images.unsplash.com/photo-1559028012-481c04fa702d?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Brand Strategy Workshops",
        paragraphs: [
          "Our collaborative workshops bring together key stakeholders to align on your brand's purpose, positioning, and personality. These interactive sessions generate insights that inform strategic decision-making.",
          "Through carefully designed exercises and discussions, we help you uncover your brand's true north—the guiding principles that will inform everything from product development to customer experience to marketing communications."
        ],
        image: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?q=80&w=800&auto=format&fit=crop"
      }
    ]
  };

  return <PageTemplate {...pageData} />;
};

export default BrandStrategy;