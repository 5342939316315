import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const HeaderContainer = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavToggle = styled.button`
  z-index: 200;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavIcon = styled.div`
  position: relative;
  width: 30px;
  height: 2px;
  background: ${props => props.$isOpen ? 'transparent' : 'var(--black)'};
  transition: var(--transition);
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: var(--black);
    transition: var(--transition);
  }
  
  &::before {
    transform: ${props => props.$isOpen ? 'rotate(45deg)' : 'translateY(-10px)'};
  }
  
  &::after {
    transform: ${props => props.$isOpen ? 'rotate(-45deg)' : 'translateY(10px)'};
  }
`;

const NavOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const NavLinks = styled(motion.nav)`
  text-align: center;
  
  ul {
    list-style: none;
  }
`;

const NavItem = styled(motion.li)`
  margin: 2rem 0;
`;

const NavItemLink = styled(Link)`
  font-size: 2rem;
  font-weight: 300;
  display: inline-block;
  
  &:hover {
    color: var(--gray);
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

const Header = ({ scrollY }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  
  const headerVariants = {
    default: { backgroundColor: 'rgba(255, 255, 255, 0)' },
    scrolled: { backgroundColor: 'rgba(255, 255, 255, 0.9)', boxShadow: '0 10px 30px rgba(0, 0, 0, 0.05)' }
  };

  const logoColor = scrollY > 50 ? 'var(--black)' : 'var(--black)';
  const textColor = scrollY > 50 ? 'var(--black)' : 'var(--black)';
  
  return (
    <>
      <HeaderContainer 
        variants={headerVariants}
        animate={scrollY > 50 ? 'scrolled' : 'default'}
        transition={{ duration: 0.3 }}
      >
        <LogoLink to="/">
          <Logo color={logoColor} textColor={textColor} />
        </LogoLink>
        <NavToggle onClick={() => setIsNavOpen(!isNavOpen)}>
          <NavIcon $isOpen={isNavOpen} />
        </NavToggle>
      </HeaderContainer>
      
      <AnimatePresence>
        {isNavOpen && (
          <NavOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <NavLinks
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <ul>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/" onClick={() => setIsNavOpen(false)}>Home</NavItemLink>
                </NavItem>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/services" onClick={() => setIsNavOpen(false)}>Services</NavItemLink>
                </NavItem>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/portfolio" onClick={() => setIsNavOpen(false)}>Portfolio</NavItemLink>
                </NavItem>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/pricing" onClick={() => setIsNavOpen(false)}>Pricing</NavItemLink>
                </NavItem>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/about" onClick={() => setIsNavOpen(false)}>About</NavItemLink>
                </NavItem>
                <NavItem whileHover={{ x: 10 }}>
                  <NavItemLink to="/contact" onClick={() => setIsNavOpen(false)}>Contact</NavItemLink>
                </NavItem>
              </ul>
            </NavLinks>
          </NavOverlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;