import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const HeroContent = styled.div`
  z-index: 1;
`;

const HeroTitle = styled(motion.h1)`
  font-size: clamp(3rem, 10vw, 6rem);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 2rem;
  letter-spacing: -2px;
`;

const HighlightedText = styled.span`
  position: relative;
  z-index: 1;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: rgba(51, 51, 53, 0.3);
    z-index: -1;
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  max-width: 600px;
  color: var(--gray);
  margin-bottom: 3rem;
`;

const HeroButton = styled(motion.button)`
  padding: 1.2rem 2.5rem;
  border: 1px solid var(--black);
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: transparent;
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: var(--black);
    z-index: -1;
    transition: width 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  &:hover {
    color: var(--white);
    
    &::before {
      width: 100%;
    }
  }
`;

const HeroImage = styled(motion.div)`
  position: relative;
  height: 500px;
  z-index: 1;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 100px;
  
  @media (max-width: 900px) {
    height: 350px;
    margin-bottom: 50px;
  }
  
  @media (max-width: 600px) {
    height: 300px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 480px) {
    height: 250px;
  }
`;

const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Blob = styled(motion.div)`
  position: absolute;
  background: transparent;
  border: ${props => props.borderWidth || '2px'} solid ${props => props.borderColor || 'var(--black)'};
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  border-radius: ${props => props.borderRadius || '30% 70% 70% 30% / 30% 30% 70% 70%'};
  filter: blur(${props => props.blur || '0px'});
  
  @media (max-width: 900px) {
    width: ${props => props.tabletSize || props.size};
    height: ${props => props.tabletSize || props.size};
    border-width: ${props => props.tabletBorderWidth || props.borderWidth};
  }
  
  @media (max-width: 480px) {
    width: ${props => props.mobileSize || props.tabletSize || props.size};
    height: ${props => props.mobileSize || props.tabletSize || props.size};
    border-width: ${props => props.mobileBorderWidth || props.tabletBorderWidth || props.borderWidth};
    display: ${props => props.hideOnMobile ? 'none' : 'block'};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const Circle = styled(motion.div)`
  position: absolute;
  border-radius: 50%;
  background: var(--gray);
  opacity: 0.05;
`;

const FloatingShape = styled(motion.div)`
  position: absolute;
  border-radius: ${props => props.radius || '50%'};
  background: ${props => props.bg || 'var(--gray)'};
  opacity: ${props => props.opacity || '0.05'};
  width: ${props => props.size};
  height: ${props => props.size};
  z-index: 0;
`;

const Hero = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);
  const [animationsEnabled, setAnimationsEnabled] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setAnimationsEnabled(width > 1200);
    };
    
    handleResize();
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    if (!animationsEnabled) return;
    
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth - 0.5) * 20,
        y: (e.clientY / window.innerHeight - 0.5) * 20
      });
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [animationsEnabled]);
  
  const blobs = [
    {
      size: '180px',
      tabletSize: '150px',
      mobileSize: '120px',
      top: '15%',
      left: '20%',
      borderWidth: '5px',
      tabletBorderWidth: '4px',
      mobileBorderWidth: '3px',
      borderColor: 'var(--black)',
      borderRadius: '40% 60% 55% 45% / 45% 35% 65% 55%',
      animate: animationsEnabled ? { 
        x: [0, 20, 0], 
        y: [0, -15, 0],
        rotate: [0, 5, 0]
      } : { x: 0, y: 0, rotate: 0 },
      transition: { duration: 6, repeat: Infinity, ease: "easeInOut" },
      blur: '0px'
    },
    {
      size: '220px',
      tabletSize: '180px',
      mobileSize: '130px',
      top: '50%',
      right: '10%',
      borderWidth: '5px',
      tabletBorderWidth: '4px',
      mobileBorderWidth: '3px',
      borderColor: 'var(--black)',
      borderRadius: '55% 45% 50% 50% / 35% 50% 50% 65%',
      animate: animationsEnabled ? { 
        x: [0, -25, 0], 
        y: [0, 20, 0],
        rotate: [0, -8, 0]
      } : { x: 0, y: 0, rotate: 0 },
      transition: { duration: 8, repeat: Infinity, ease: "easeInOut" },
      blur: '0px'
    },
    {
      size: '120px',
      tabletSize: '100px',
      mobileSize: '80px',
      bottom: '25%',
      left: '30%',
      tabletLeft: '15%', 
      mobileLeft: '8%', 
      borderWidth: '5px',
      tabletBorderWidth: '3px',
      mobileBorderWidth: '2px',
      borderColor: 'var(--black)',
      borderRadius: '30% 70% 45% 55% / 55% 40% 60% 45%',
      animate: animationsEnabled ? { 
        x: [0, 15, 0], 
        y: [0, -10, 0],
        rotate: [0, 10, 0]
      } : { x: 0, y: 0, rotate: 0 },
      transition: { duration: 5, repeat: Infinity, ease: "easeInOut" },
      blur: '0px'
    },
    {
      size: '150px',
      tabletSize: '120px',
      mobileSize: '90px',
      top: '60%',
      left: '5%',
      tabletLeft: '10%',
      borderWidth: '5px',
      tabletBorderWidth: '3px',
      mobileBorderWidth: '2px',
      borderColor: 'var(--black)',
      borderRadius: '45% 55% 65% 35% / 40% 60% 40% 60%',
      animate: animationsEnabled ? { 
        x: [0, 10, 0], 
        y: [0, 15, 0],
        rotate: [0, -5, 0]
      } : { x: 0, y: 0, rotate: 0 },
      transition: { duration: 7, repeat: Infinity, ease: "easeInOut" },
      blur: '0px',
      hideOnMobile: true 
    },
    {
      size: '100px',
      tabletSize: '80px',
      mobileSize: '70px',
      top: '10%',
      right: '30%',
      tabletRight: '15%',
      mobileRight: '10%',
      borderWidth: '5px',
      tabletBorderWidth: '3px',
      mobileBorderWidth: '2px',
      borderColor: 'var(--black)',
      borderRadius: '50% 50% 35% 65% / 60% 40% 60% 40%',
      animate: animationsEnabled ? { 
        x: [0, -8, 0], 
        y: [0, 12, 0],
        rotate: [0, 8, 0]
      } : { x: 0, y: 0, rotate: 0 },
      transition: { duration: 4, repeat: Infinity, ease: "easeInOut" },
      blur: '0px',
      hideOnMobile: true 
    }
  ];
  
  return (
    <HeroSection id="home">
      <Container>
        <HeroContent>
          <HeroTitle
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            We create<br /><HighlightedText>digital experiences</HighlightedText>
          </HeroTitle>
          <HeroSubtitle
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            Strategic design and technology agency helping brands 
            achieve their digital potential.
          </HeroSubtitle>
          <HeroButton
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            whileTap={{ scale: 0.97 }}
          >
            Get in touch
          </HeroButton>
        </HeroContent>
        
        <HeroImage
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <BlobContainer>
            {blobs.map((blob, index) => (
              <Blob
                key={index}
                size={blob.size}
                tabletSize={blob.tabletSize}
                mobileSize={blob.mobileSize}
                style={{ 
                  top: blob.top, 
                  left: blob.left, 
                  right: blob.right, 
                  bottom: blob.bottom,
                  ['@media (max-width: 900px)']: {
                    left: blob.tabletLeft,
                    right: blob.tabletRight
                  },
                  ['@media (max-width: 480px)']: {
                    left: blob.mobileLeft,
                    right: blob.mobileRight
                  }
                }}
                borderWidth={blob.borderWidth}
                tabletBorderWidth={blob.tabletBorderWidth}
                mobileBorderWidth={blob.mobileBorderWidth}
                borderColor={blob.borderColor}
                borderRadius={blob.borderRadius}
                animate={blob.animate}
                transition={blob.transition}
                blur={blob.blur}
                hideOnMobile={blob.hideOnMobile}
              />
            ))}
          </BlobContainer>
        </HeroImage>
      </Container>
      
      <Background>
        {animationsEnabled ? (
          <>
            <FloatingShape 
              size="100px"
              top="20%"
              right="20%"
              bg="var(--black)"
              opacity="0.03"
              radius="30% 70% 70% 30% / 30% 30% 70% 70%"
              animate={{ 
                y: [0, -20, 0], 
                rotate: [0, 10, 0],
                scale: [1, 1.05, 1]
              }}
              transition={{ 
                duration: 8, 
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            <FloatingShape 
              size="60px"
              bottom="25%"
              left="15%"
              bg="var(--black)"
              opacity="0.02"
              radius="50%"
              animate={{ 
                y: [0, 15, 0], 
                x: [0, 10, 0],
              }}
              transition={{ 
                duration: 6, 
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            <FloatingShape 
              size="40px"
              top="25%"
              left="30%"
              bg="var(--black)"
              opacity="0.04"
              radius="2px"
              animate={{ 
                rotate: [0, 180, 0]
              }}
              transition={{ 
                duration: 12, 
                repeat: Infinity,
                ease: "linear"
              }}
            />
            <Circle
              style={{ 
                width: '300px', 
                height: '300px', 
                top: '10%', 
                right: '5%',
                transform: `translate(${-mousePosition.x}px, ${-mousePosition.y}px)`
              }}
              animate={{ 
                scale: [1, 1.05, 1], 
                opacity: [0.05, 0.07, 0.05] 
              }}
              transition={{ 
                duration: 8, 
                repeat: Infinity 
              }}
            />
          </>
        ) : (
          <>
            <FloatingShape 
              size="100px"
              top="20%"
              right="20%"
              bg="var(--black)"
              opacity="0.03"
              radius="30% 70% 70% 30% / 30% 30% 70% 70%"
            />
            <FloatingShape 
              size="60px"
              bottom="25%"
              left="15%"
              bg="var(--black)"
              opacity="0.02"
              radius="50%"
            />
            <Circle
              style={{ 
                width: '300px', 
                height: '300px', 
                top: '10%', 
                right: '5%',
              }}
              opacity={0.05}
            />
          </>
        )}
        <Circle
          style={{ 
            width: '500px', 
            height: '500px', 
            bottom: '-10%', 
            left: '-5%' 
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 0.05 }}
          transition={{ duration: 1, delay: 0.4 }}
        />
      </Background>
    </HeroSection>
  );
};

export default Hero;