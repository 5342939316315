import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import PageTransition from '../components/PageTransition';

const PricingSection = styled.section`
  padding: 180px 0 120px;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const PageTitle = styled(motion.h1)`
  font-size: clamp(3rem, 8vw, 4.5rem);
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const PageSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto 60px;
`;

const PriceToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const ToggleOption = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  color: ${props => props.active ? 'var(--black)' : 'var(--gray)'};
  font-weight: ${props => props.active ? '600' : '400'};
  position: relative;
  transition: var(--transition);
`;

const ToggleIndicator = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: var(--black);
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-bottom: 80px;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PricingCard = styled(motion.div)`
  background: ${props => props.featured ? '#f9f9f9' : 'transparent'};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px;
  position: relative;
  transition: var(--transition);
  overflow: hidden;
  
  &::before {
    content: ${props => props.featured ? "'Most Popular'" : "none"};
    position: absolute;
    top: 12px;
    right: -30px;
    background: var(--black);
    color: white;
    font-size: 0.8rem;
    padding: 5px 30px;
    transform: rotate(45deg);
    font-weight: 500;
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.2);
  }
`;

const PriceIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const PriceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 600;
`;

const PriceDescription = styled.p`
  color: var(--gray);
  margin-bottom: 30px;
  font-size: 1rem;
  line-height: 1.5;
`;

const PriceAmount = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
`;

const PriceNumber = styled.span`
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -1px;
`;

const PriceCurrency = styled.span`
  font-size: 1.5rem;
  margin-right: 5px;
`;

const PricePeriod = styled.span`
  color: var(--gray);
  font-size: 1rem;
  margin-left: 10px;
`;

const SetupFee = styled.div`
  font-size: 0.9rem;
  margin-bottom: 30px;
  color: var(--gray);
  font-weight: 500;
`;

const PriceFeatures = styled.ul`
  list-style: none;
  margin-bottom: 30px;
`;

const PriceFeature = styled.li`
  padding: 10px 0;
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const FeatureCheck = styled.span`
  margin-right: 10px;
  color: var(--black);
`;

const PriceButton = styled(motion.button)`
  width: 100%;
  padding: 16px 0;
  background: ${props => props.featured ? 'var(--black)' : 'transparent'};
  color: ${props => props.featured ? 'var(--white)' : 'var(--black)'};
  border: 1px solid var(--black);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: ${props => props.featured ? 'rgba(255, 255, 255, 0.1)' : 'var(--black)'};
    transition: width 0.4s ease;
    z-index: 0;
  }
  
  &:hover {
    color: ${props => props.featured ? 'var(--white)' : 'var(--white)'};
    
    &::before {
      width: 100%;
    }
  }
  
  span {
    position: relative;
    z-index: 1;
  }
`;

const InfoSection = styled.div`
  margin-bottom: 80px;
`;

const InfoTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled(motion.div)`
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.2);
  }
  
  transition: var(--transition);
`;

const InfoIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const InfoCardTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 15px;
  font-weight: 500;
`;

const InfoCardText = styled.p`
  color: var(--gray);
  line-height: 1.6;
`;

const FAQSection = styled.div`
  margin-bottom: 80px;
`;

const FAQTitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
`;

const FAQGrid = styled.div`
  display: grid;
  gap: 20px;
`;

const FAQItem = styled(motion.div)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

const FAQQuestion = styled.button`
  width: 100%;
  padding: 25px 30px;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  span {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
    transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }
`;

const FAQAnswer = styled(motion.div)`
  padding: 0 30px;
  color: var(--gray);
  line-height: 1.6;
  overflow: hidden;
`;

const CTASection = styled.div`
  text-align: center;
  padding: 80px 0;
  background: #f9f9f9;
  border-radius: 8px;
`;

const CTATitle = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 20px;
`;

const CTAText = styled(motion.p)`
  color: var(--gray);
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 30px;
`;

const CTAButton = styled(motion(Link))`
  display: inline-block;
  padding: 16px 32px;
  background: var(--black);
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4px;
  text-decoration: none;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
  }
`;

const DisclaimerSection = styled.div`
  margin: 20px auto 80px;
  padding: 30px 40px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  border-left: 3px solid var(--black);
  max-width: 900px;
`;

const DisclaimerTitle = styled(motion.h3)`
  font-size: 1.5rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DisclaimerText = styled(motion.p)`
  color: var(--gray);
  line-height: 1.6;
  margin-bottom: 15px;
`;

const CustomOptionsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin: 20px 0;
  list-style: none;
`;

const CustomOption = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [activeQuestion, setActiveQuestion] = useState(null);
  
  const toggleQuestion = (index) => {
    if (activeQuestion === index) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(index);
    }
  };
  
  const faqItems = [
    {
      question: "What's included in the monthly subscription?",
      answer: "Our monthly subscription includes website hosting, regular maintenance, bug fixes, minor content updates, and basic technical support. We ensure your site stays up-to-date, secure, and running smoothly."
    },
    {
      question: "Is there a minimum commitment period?",
      answer: "Yes, we recommend a minimum 3-month commitment to ensure we can properly establish and optimize your project. After this initial period, you can continue on a month-to-month basis."
    },
    {
      question: "Can I cancel my subscription?",
      answer: "Yes, you can cancel at any time after the initial commitment period. We also offer a buyout option ($75-100) that allows you to keep your site without the monthly maintenance fee."
    },
    {
      question: "How do custom features affect pricing?",
      answer: "Custom features may affect both the one-time setup fee and monthly subscription. We'll provide a detailed quote based on the complexity and development time required for your specific needs."
    },
    {
      question: "Do you offer student discounts?",
      answer: "Yes! Our pricing model is specifically designed to be accessible for student entrepreneurs and early-stage startups. We may offer additional discounts for educational projects - just ask during your consultation."
    }
  ];
  
  return (
    <PageTransition>
      <PricingSection>
        <Container>
          <PageHeader>
            <PageTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Simple, Transparent Pricing
            </PageTitle>
            <PageSubtitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              Affordable solutions for student startups and early-stage founders. Pay an initial setup fee, then a low monthly subscription that grows with your needs.
            </PageSubtitle>
          </PageHeader>
          
          <DisclaimerSection>
            <DisclaimerTitle
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4 }}
            >
              <span>✧</span> Custom Pricing & Options
            </DisclaimerTitle>
            <DisclaimerText
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: 0.1 }}
            >
              The prices shown below are baseline examples. We provide fully customized pricing tailored to your specific project requirements, timeline, and budget. Our goal is to create flexible solutions that fit your needs perfectly.
            </DisclaimerText>
            
            <DisclaimerText
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: 0.15 }}
            >
              Popular add-ons and customizations include:
            </DisclaimerText>
            
            <CustomOptionsList>
              {[
                "Additional pages", 
                "Advanced animations", 
                "Custom features",
                "E-commerce functionality",
                "User authentication",
                "CMS integration",
                "Performance optimization",
                "Multi-language support"
              ].map((option, index) => (
                <CustomOption
                  key={index}
                  initial={{ opacity: 0, x: -10 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3, delay: 0.1 + (index * 0.05) }}
                >
                  <span style={{ color: 'var(--black)' }}>✓</span> {option}
                </CustomOption>
              ))}
            </CustomOptionsList>
            
            <DisclaimerText
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: 0.2 }}
            >
              Contact us for a personalized quote that addresses your exact requirements.
            </DisclaimerText>
          </DisclaimerSection>
          <PriceToggle>
            <ToggleOption 
              active={billingCycle === 'monthly'} 
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly Billing
              {billingCycle === 'monthly' && (
                <ToggleIndicator layoutId="toggleIndicator" />
              )}
            </ToggleOption>
            <ToggleOption 
              active={billingCycle === 'quarterly'} 
              onClick={() => setBillingCycle('quarterly')}
            >
              Quarterly Billing (10% off)
              {billingCycle === 'quarterly' && (
                <ToggleIndicator layoutId="toggleIndicator" />
              )}
            </ToggleOption>
          </PriceToggle>
          <PricingGrid>
            {/* Starter Site */}
            <PricingCard
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <PriceIcon>✦</PriceIcon>
              <PriceTitle>Simple Site</PriceTitle>
              <PriceDescription>
                Perfect for personal portfolios and simple landing pages
              </PriceDescription>
              
              <PriceAmount>
                <PriceCurrency>$</PriceCurrency>
                <PriceNumber>{billingCycle === 'monthly' ? '12' : '32'}</PriceNumber>
                <PricePeriod>/{billingCycle === 'monthly' ? 'mo' : '3mo'}</PricePeriod>
              </PriceAmount>
              
              <SetupFee>One-time setup fee: $40-60</SetupFee>
              
              <PriceFeatures>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  1-4 responsive pages
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Contact form
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Basic SEO setup
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Fast, reliable hosting
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Monthly content updates
                </PriceFeature>
              </PriceFeatures>
              
              <PriceButton whileTap={{ scale: 0.98 }}>
                <span>Get Started</span>
              </PriceButton>
            </PricingCard>
            
            {/* Advanced Site */}
            <PricingCard
              featured
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              <PriceIcon>✧</PriceIcon>
              <PriceTitle>Advanced Site</PriceTitle>
              <PriceDescription>
                Ideal for business websites and multi-page projects
              </PriceDescription>
              
              <PriceAmount>
                <PriceCurrency>$</PriceCurrency>
                <PriceNumber>{billingCycle === 'monthly' ? '20' : '54'}</PriceNumber>
                <PricePeriod>/{billingCycle === 'monthly' ? 'mo' : '3mo'}</PricePeriod>
              </PriceAmount>
              
              <SetupFee>One-time setup fee: $60-120</SetupFee>
              
              <PriceFeatures>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  4-6 responsive pages
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Blog/content management
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Custom animations
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Advanced SEO implementation
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Regular content updates
                </PriceFeature>
              </PriceFeatures>
              
              <PriceButton featured whileTap={{ scale: 0.98 }}>
                <span>Get Started</span>
              </PriceButton>
            </PricingCard>
            
            {/* Starter App */}
            <PricingCard
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <PriceIcon>◆</PriceIcon>
              <PriceTitle>Simple App</PriceTitle>
              <PriceDescription>
                For interactive web applications and MVPs
              </PriceDescription>
              
              <PriceAmount>
                <PriceCurrency>$</PriceCurrency>
                <PriceNumber>{billingCycle === 'monthly' ? '25' : '67'}</PriceNumber>
                <PricePeriod>/{billingCycle === 'monthly' ? 'mo' : '3mo'}</PricePeriod>
              </PriceAmount>
              
              <SetupFee>One-time setup fee: $120-150</SetupFee>
              
              <PriceFeatures>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Basic application functionality
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  User dashboard
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Form processing & validation
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Basic API integration
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Technical support
                </PriceFeature>
              </PriceFeatures>
              
              <PriceButton whileTap={{ scale: 0.98 }}>
                <span>Get Started</span>
              </PriceButton>
            </PricingCard>
            
            {/* Advanced App */}
            <PricingCard
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <PriceIcon>◇</PriceIcon>
              <PriceTitle>Advanced App</PriceTitle>
              <PriceDescription>
                Full-featured web applications with complex functionality
              </PriceDescription>
              
              <PriceAmount>
                <PriceCurrency>$</PriceCurrency>
                <PriceNumber>{billingCycle === 'monthly' ? '40' : '108'}</PriceNumber>
                <PricePeriod>/{billingCycle === 'monthly' ? 'mo' : '3mo'}</PricePeriod>
              </PriceAmount>
              
              <SetupFee>One-time setup fee: $200+</SetupFee>
              
              <PriceFeatures>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Advanced application functionality
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Authentication system
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Complex user flows
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Multiple API integrations
                </PriceFeature>
                <PriceFeature>
                  <FeatureCheck>✓</FeatureCheck>
                  Priority technical support
                </PriceFeature>
              </PriceFeatures>
              
              <PriceButton whileTap={{ scale: 0.98 }}>
                <span>Get Started</span>
              </PriceButton>
            </PricingCard>
          </PricingGrid>
          
          <InfoSection>
            <InfoTitle
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Why Our Pricing Is Different
            </InfoTitle>
            
            <InfoGrid>
              <InfoCard
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.1 }}
              >
                <InfoIcon>✦</InfoIcon>
                <InfoCardTitle>Built for Students</InfoCardTitle>
                <InfoCardText>
                  Our pricing is designed specifically for students and early-stage founders with limited budgets but big ambitions. Low upfront costs with flexible monthly plans.
                </InfoCardText>
              </InfoCard>
              
              <InfoCard
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <InfoIcon>◆</InfoIcon>
                <InfoCardTitle>Grows With You</InfoCardTitle>
                <InfoCardText>
                  As your project evolves, our subscription model scales with your needs. Add features, pages, or functionality without breaking your budget.
                </InfoCardText>
              </InfoCard>
              
              <InfoCard
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <InfoIcon>✧</InfoIcon>
                <InfoCardTitle>Ongoing Partnership</InfoCardTitle>
                <InfoCardText>
                  We're not just building a website and disappearing. Our subscription model means we're invested in your long-term success and continuous improvement.
                </InfoCardText>
              </InfoCard>
            </InfoGrid>
          </InfoSection>
          
          <FAQSection>
            <FAQTitle
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Frequently Asked Questions
            </FAQTitle>
            
            <FAQGrid>
              {faqItems.map((item, index) => (
                <FAQItem
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6, delay: 0.1 * index }}
                >
                  <FAQQuestion 
                    isOpen={activeQuestion === index}
                    onClick={() => toggleQuestion(index)}
                  >
                    {item.question}
                    <span>+</span>
                  </FAQQuestion>
                  
                  <AnimatePresence>
                    {activeQuestion === index && (
                      <FAQAnswer
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <p style={{ padding: "0 0 25px 0" }}>{item.answer}</p>
                      </FAQAnswer>
                    )}
                  </AnimatePresence>
                </FAQItem>
              ))}
            </FAQGrid>
          </FAQSection>
          
          <CTASection>
            <CTATitle
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              Ready to Bring Your Idea to Life?
            </CTATitle>
            
            <CTAText
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.1 }}
            >
              Let's discuss your project and find the perfect pricing plan for your specific needs. No unexpected costs, just transparent pricing and exceptional service.
            </CTAText>
            
            <CTAButton
              to="/contact"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              whileHover={{ y: -5, boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)" }}
              whileTap={{ y: 0, boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }}
            >
              Schedule a Consultation
            </CTAButton>
          </CTASection>
        </Container>
      </PricingSection>
    </PageTransition>
  );
};

export default PricingPage;