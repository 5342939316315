import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ServicesSection = styled.section`
  padding: 120px 0;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionHeader = styled.div`
  margin-bottom: 80px;
  max-width: 700px;
`;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
`;

const ServiceCard = styled(motion.div)`
  padding: 40px;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: var(--transition);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  }
`;

const ServiceIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 20px;
  opacity: 0.8;
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: 500;
`;

const ServiceDescription = styled.p`
  color: var(--gray);
  line-height: 1.6;
`;

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.6,
      ease: [0.215, 0.61, 0.355, 1]
    }
  })
};

const Services = () => {
  const services = [
    {
      icon: "✦",
      title: "Brand Strategy",
      description: "We build brands that stand out with strategic positioning and compelling storytelling."
    },
    {
      icon: "✧",
      title: "Web Development",
      description: "Custom websites with clean code and stunning designs that perform across all devices."
    },
    {
      icon: "◆",
      title: "Digital Marketing",
      description: "Data-driven marketing strategies that connect with your audience and drive growth."
    },
    {
      icon: "◇",
      title: "UX/UI Design",
      description: "User-centered design that creates intuitive, engaging digital experiences."
    },
    {
      icon: "◈",
      title: "SEO Optimization",
      description: "Boost your visibility with search strategies that drive traffic and improve rankings."
    },
    {
      icon: "✺",
      title: "Content Creation",
      description: "Engaging copy, visuals, and media that bring your brand's voice to life and keep audiences hooked."
    }
  ];

  return (
    <ServicesSection id="services">
      <Container>
        <SectionHeader>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Our Services
          </SectionTitle>
          <SectionSubtitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            We deliver end-to-end digital solutions that help businesses transform and thrive in an increasingly digital world.
          </SectionSubtitle>
        </SectionHeader>

        <ServicesGrid>
          {services.map((service, index) => (
            <ServiceCard 
              key={index}
              custom={index}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <ServiceIcon>{service.icon}</ServiceIcon>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </Container>
    </ServicesSection>
  );
};

export default Services;