import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Contact from '../components/Contact';

const ContactPageSection = styled.div`
  padding-top: 80px;
`;

const ContactPage = () => {
  return (
    <ContactPageSection>
      <Contact />
    </ContactPageSection>
  );
};

export default ContactPage;