import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LogoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LogoSymbol = styled.svg`
  width: 32px;
  height: 32px;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -1px;
`;

const Logo = ({ color = "currentColor", textColor = "currentColor" }) => {
  return (
    <LogoContainer>
      <LogoSymbol 
        viewBox="0 0 100 100" 
        fill={color}
        initial={{ rotate: -5 }}
        whileHover={{ rotate: 0, scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <motion.path 
          d="M25,20 C35,10 65,15 75,25 C85,35 90,65 80,75 C70,85 40,85 25,75 C15,65 15,30 25,20 Z"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
        />
      </LogoSymbol>
      <LogoText style={{ color: textColor }}>Auxena</LogoText>
    </LogoContainer>
  );
};

export default Logo;