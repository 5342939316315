import React from 'react';
import PageTemplate from '../components/PageTemplate';

const DigitalMarketing = () => {
  const pageData = {
    title: "Digital Marketing",
    subtitle: "Data-driven marketing strategies that connect with your audience and drive measurable growth for your business.",
    sections: [
      {
        title: "Content Marketing",
        paragraphs: [
          "Great content is the foundation of digital marketing success. We create strategic content that attracts, engages, and converts your ideal customers by providing genuine value at every touchpoint.",
          "Our content strategies go beyond blog posts to include video, podcasts, infographics, case studies, whitepapers, and more—all designed to build your authority and generate qualified leads."
        ],
        image: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Social Media Marketing",
        paragraphs: [
          "We help brands build meaningful connections on social platforms where their audience spends time. Our approach focuses on authentic engagement rather than vanity metrics.",
          "From content creation to community management to paid campaigns, we develop comprehensive social strategies that amplify your brand voice and drive measurable business results."
        ],
        image: "https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Performance Marketing",
        paragraphs: [
          "Our performance marketing services focus on generating measurable results through paid advertising channels. We optimize campaigns for conversions, constantly refining based on real-time data.",
          "From Google Ads to social media advertising to programmatic display, we create targeted campaigns that reach the right audience at the right time with messages that drive action."
        ],
        image: "https://images.unsplash.com/photo-1533750349088-cd871a92f312?q=80&w=800&auto=format&fit=crop"
      }
    ]
  };

  return <PageTemplate {...pageData} />;
};

export default DigitalMarketing;