import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutSection = styled.section`
  padding: 120px 0;
  background-color: var(--white);
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const AboutText = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--gray);
  line-height: 1.8;
  margin-bottom: 30px;
`;

const AboutStats = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 20px;
`;

const StatItem = styled(motion.div)``;

const StatNumber = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 5px;
`;

const StatLabel = styled.div`
  color: var(--gray);
  font-size: 1rem;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 400px;
`;

const Image = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  background-image: url('https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=800&auto=format&fit=crop');
  background-size: cover;
  background-position: center;
`;

const About = () => {
  return (
    <AboutSection id="about">
      <Container>
        <AboutContent>
          <SectionTitle
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            About Us
          </SectionTitle>
          <AboutText
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            We are a team of strategists, designers, and developers who are passionate about creating digital experiences that elevate brands and drive growth.
          </AboutText>
          <AboutText
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Founded in 2025, our goal is to allow you to focus on building your product, while we take care of your online presence.
          </AboutText>
          
          <AboutStats>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <StatNumber>5+</StatNumber>
              <StatLabel>Clients Worldwide</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <StatNumber>10+</StatNumber>
              <StatLabel>Projects Completed</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <StatNumber>4+</StatNumber>
              <StatLabel>Years Experience</StatLabel>
            </StatItem>
            <StatItem
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.6 }}
            >
              <StatNumber>2</StatNumber>
              <StatLabel>Team Members</StatLabel>
            </StatItem>
          </AboutStats>
        </AboutContent>
        
        <ImageContainer>
          <Image 
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          />
        </ImageContainer>
      </Container>
    </AboutSection>
  );
};

export default About;