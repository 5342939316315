import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const TestimonialsSection = styled.section`
  padding: 120px 0;
  background-color: var(--white);
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: -1px;
`;

const SectionSubtitle = styled(motion.p)`
  font-size: 1.2rem;
  color: var(--gray);
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto 60px;
`;

const TestimonialSlider = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;
`;

const TestimonialItem = styled(motion.div)`
  background: #f9f9f9;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: left;
`;

const TestimonialQuote = styled.blockquote`
  font-size: 1.3rem;
  line-height: 1.6;
  margin-bottom: 30px;
  position: relative;
  
`;

const ClientInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ClientImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ddd;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  margin-right: 20px;
`;

const ClientDetails = styled.div``;

const ClientName = styled.h4`
  font-size: 1.1rem;
  margin-bottom: 5px;
`;

const ClientRole = styled.p`
  color: var(--gray);
  font-size: 0.9rem;
`;

const SliderControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
`;

const SliderButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    background: var(--black);
    color: var(--white);
  }
`;

const SliderDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
`;

const Dot = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.active ? 'var(--black)' : 'rgba(0, 0, 0, 0.1)'};
  border: none;
  padding: 0;
  cursor: pointer;
  transition: var(--transition);
`;

const Testimonials = () => {
  const testimonials = [
    {
      quote: "Auxena transformed our digital presence completely. Their strategic approach and attention to detail resulted in a website that not only looks stunning but also delivers measurable business results.",
      name: "Sarah Johnson",
      role: "CEO at Altitude Ventures",
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=200&auto=format"
    },
    {
      quote: "Working with Auxena was a game-changer for our brand. They took the time to understand our vision and translated it into a digital experience that exceeded our expectations.",
      name: "Michael Chen",
      role: "Marketing Director at Prism Tech",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=200&auto=format"
    },
    {
      quote: "The team at Auxena brings both creativity and strategic thinking to every project. They delivered a website that not only looks beautiful but also drives conversion and engagement.",
      name: "Emma Rodriguez",
      role: "Founder of Lumina Studios",
      image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=200&auto=format"
    }
  ];
  
  const [current, setCurrent] = useState(0);
  
  const nextSlide = () => {
    setCurrent(current === testimonials.length - 1 ? 0 : current + 1);
  };
  
  const prevSlide = () => {
    setCurrent(current === 0 ? testimonials.length - 1 : current - 1);
  };
  
  const goToSlide = (index) => {
    setCurrent(index);
  };
  
  return (
    <TestimonialsSection id="testimonials">
      <Container>
        <SectionTitle
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Client Testimonials
        </SectionTitle>
        <SectionSubtitle
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          Don't just take our word for it. Here's what our clients have to say about working with us.
        </SectionSubtitle>
        
        <TestimonialSlider>
          <AnimatePresence mode="wait">
            <TestimonialItem
              key={current}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <TestimonialQuote>
                {testimonials[current].quote}
              </TestimonialQuote>
              <ClientInfo>
                <ClientImage image={testimonials[current].image} />
                <ClientDetails>
                  <ClientName>{testimonials[current].name}</ClientName>
                  <ClientRole>{testimonials[current].role}</ClientRole>
                </ClientDetails>
              </ClientInfo>
            </TestimonialItem>
          </AnimatePresence>
          
          <SliderControls>
            <SliderButton onClick={prevSlide}>
              ←
            </SliderButton>
            <SliderButton onClick={nextSlide}>
              →
            </SliderButton>
          </SliderControls>
          
          <SliderDots>
            {testimonials.map((_, index) => (
              <Dot 
                key={index} 
                active={current === index} 
                onClick={() => goToSlide(index)} 
              />
            ))}
          </SliderDots>
        </TestimonialSlider>
      </Container>
    </TestimonialsSection>
  );
};

export default Testimonials;