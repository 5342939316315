import React from 'react';
import PageTemplate from '../components/PageTemplate';

const SEOOptimization = () => {
  const pageData = {
    title: "SEO Optimization",
    subtitle: "Boost your visibility with search strategies that drive organic traffic, improve rankings, and deliver measurable business results.",
    sections: [
      {
        title: "Technical SEO Audits",
        paragraphs: [
          "Your website's technical foundation is crucial for SEO success. We conduct comprehensive audits to identify and fix issues that may be holding back your search performance.",
          "From site speed optimization to mobile responsiveness to structured data implementation, we ensure your site meets technical best practices that help search engines effectively crawl and index your content."
        ],
        image: "https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "On-Page SEO Optimization",
        paragraphs: [
          "We optimize your existing content to perform better in search results. Our approach focuses on relevance and user intent, not just keyword density.",
          "From title tags to internal linking to content structure, we implement proven on-page strategies that help your most important pages rank higher for targeted search terms."
        ],
        image: "https://images.unsplash.com/photo-1523800503107-5bc3ba2a6f81?q=80&w=800&auto=format&fit=crop"
      },
      {
        title: "Content Strategy for SEO",
        paragraphs: [
          "Content and SEO work hand in hand. We develop strategic content plans that address topics your audience is searching for while filling gaps in your current content ecosystem.",
          "Our SEO content strategies focus on building topical authority in your industry, creating clusters of related content that establish your site as a valuable resource worthy of high rankings."
        ],
        image: "https://images.unsplash.com/photo-1543286386-713bdd548da4?q=80&w=800&auto=format&fit=crop"
      }
    ]
  };

  return <PageTemplate {...pageData} />;
};

export default SEOOptimization;